import React,{useContext} from 'react'
import RootContext from '../../context/RootContext';
import OpenNewTab from '../../hooks/OpenNewTab'
import './Footer.css'
import { InstitutionLocationData } from '../../data/InstitutionLocationData';
import { ContactInfoData } from '../../data/ContactInfoData';
import { NavLink } from 'react-router-dom';
import { FooterMenuData } from '../../data/FooterMenuData';
import { SocialMediaData } from '../../data/SocialMediaData';

const Footer = () => {
  const {year} = useContext(RootContext)
  const {openInNewTab} = OpenNewTab();
  return (
    <div className="footer-container text-white py-4">
        <div className="row px-2">


        <div className="col-md-6 col-lg-3 px-3">
              <h5 className="py-3">
                 <img src="/asset/img/logo/kyamch-logo.png" alt="logo" className='logo'/>
              </h5>
               <small className='text-justify'>
                   Khwaja Yunus Ali Medical College & Hospital (KYAMCH) is situated on 127 acres of land has one of the largest campuses in southeast Asia, Khwaja Yunus Ali Medical College & Hospital (KYAMCH) started its journey on May 17, 2004
               </small>
           </div>


          <div className="col-md-6 col-lg-3 px-3">
            <h6 className="py-3">QUICK MENU</h6>
              <ul className='list-unstyled menu'>
                {
                  FooterMenuData.map( (item, index) => 
                  <li key={index}>
                    <NavLink to ={item.route} className="text-decoration-none text-white">
                      {item.icon}
                      {item.caption}
                    </NavLink>
                  </li>
                  )
                }
                <li>
                  <NavLink to="" onClick={() => openInNewTab('https://kyamch.org:2096/')} className='text-white text-decoration-none'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" className=" me-2 bi bi-envelope-at-fill" viewBox="0 0 16 16">
                      <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2H2Zm-2 9.8V4.698l5.803 3.546L0 11.801Zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 9.671V4.697l-5.803 3.546.338.208A4.482 4.482 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671Z"/>
                      <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034v.21Zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791Z"/>
                    </svg>
                      Web Mail
                  </NavLink>
                </li>
              </ul>
          </div>

          <div className="col-md-6 col-lg-3 px-3">
            <h6 className="py-3">CONTACT INFO</h6>
              {
                ContactInfoData.map( (item, index) => 
                  <div key={index} className='py-1'>
                    <small>
                        {item.icon}
                        {item.caption}
                    </small>
                    <br />
                  </div>
                
                )
              }
          </div>

          <div className="col-md-6 col-lg-3 px-3">
            <h6 className="py-3">OUR LOCATION</h6>
              {
                InstitutionLocationData.map( (item, index) => 
                 <div key={index} className='py-1'>
                    <small>
                      {item.icon}
                      {item.caption}
                    </small>
                    <br />
                 </div>
                )
              }

              <div className="social-icon-container d-flex flex-row mt-4 pt-2">
                {
                  SocialMediaData.map( (item, index) => 
                    <div className={['icon','position-relative d-flex flex-column justify-content-center align-items-center text-center cursor-pointer',item.socialCSS].join(" ")} key={index}>
                      <div className="tooltip-title">{item.socialTooltip}</div>
                      <NavLink to="" onClick={(e) => openInNewTab(item.pageLink)} className="text-dark" target="_blank"><span className='shadow'>{item.socialIcon}</span></NavLink>
                    </div>
                  )
                }                                                                    
              </div>
          </div>
        </div>
      <hr/>
      <div className="text-center px-1">
        <small>COPYRIGHT &copy; 2004- {year} ALL RIGHTS RESERVED BY KYAMCH || THIS WEBSITE IS DESIGNED, DEVELOPED AND MAINTAINED BY KYAMCH <span className='text-info cursor-pointer text-decoration-underline' onClick={(e) => openInNewTab("#")} title=''>IT DEPARTMENT</span></small>
      </div>
    </div>
  )
}

export default Footer