import { NavLink } from "react-router-dom";
import OpenNewTab from "../hooks/OpenNewTab";

const LogoCarouselLayout = (props) => {
    const {openInNewTab} = OpenNewTab();
  return (
    <>
        <div className='rounded-circle border border-info shadow p-3 mx-2' style={{maxWidth: '160px', height: '160px'}} data-aos='fade-up'>
            <NavLink to="" onClick={(e) => openInNewTab(props.pageLink)} className="" target="_blank">
                  <img src={props.imgsrc} alt={props.alt} className="w-100" style={{maxWidth: '150px'}}/>
              </NavLink>
        </div>
    </>
  )
}

export default LogoCarouselLayout