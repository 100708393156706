import React from 'react'

const HealthDiagnosticLayout = (props) => {
  return (
    <div className="health-package py-3 m-2 border box-hover rounded-4" data-aos="fade-up">
        <div className="">
            <img src={'/asset/img/health-services/'+props.img} alt={props.caption} className='w-100 rounded-circle d-block mx-auto border border-success' style={{maxWidth: '150px'}}/>
        </div>
        <div className="p-3">
            <h5 className='text-success text-uppercase text-center'>{props.caption}</h5>
               <div className='text-justify'>
                 {props.content}
               </div>

        </div>
    </div> 
  )
}

export default HealthDiagnosticLayout