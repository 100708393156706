import React, {useContext, useEffect, Suspense, lazy} from 'react'
import { Routes, Route} from 'react-router-dom'
import FrontendLayout from "./FrontendLayout"
import ScrollToTop from '../components/ScrollToTop/ScrollToTop'
import RootContext from '../context/RootContext'
import Loader from '../Loader/Loader'
import Home from '../views/Home/Home'
import SubMenuLayout from  './SubMenuLayout'
import PDFView from '../views/PDFView'

const IPDOPD = lazy(() => import('../views/Home/IPDOPD'));

// Speciality
const DentalUnit = lazy(() => import('../views/Speciality/DentalUnit'));
const CardiologySpeciality = lazy(() => import('../views/Speciality/Cardiology'));
const CardiacSurgerySpeciality = lazy(() => import('../views/Speciality/CardiacSurgery'));
const NeurosurgerySpeciality = lazy(() => import('../views/Speciality/Neurosurgery'));

const CancerCenter = lazy(() => import('../views/Speciality/CancerCenter'));
const TestimonialDetailsLayout = lazy(() => import('../views/Testimonial/TestimonialDetailsLayout'));

// About Menu
const Founder = lazy(() => import('../views/About/Founder'));
const BOT = lazy(() => import('../views/About/BOT'));
const BOTProfile = lazy(() => import('../views/About/BOTProfile'));

const BOTDirector = lazy(() => import('../views/About/Messages/BOTDirector'));
const KYAMCHDirector = lazy(() => import('../views/About/Messages/KYAMCHDirector'));
const MissionVision = lazy(() => import('../views/About/MissionVision'));
const CertificatesAchievements = lazy(() => import('../views/About/CertificatesAchievements'));
const AcheivementList = lazy(() => import('../views/About/AcheivementList'));
const AcheivementListDetails = lazy(() => import('../views/About/AcheivementListDetails'));

// Consultans Menu
const Consultants = lazy(() => import('../views/Consultants/Consultants'));
const ConsultantProfile = lazy(() => import('../views/Consultants/ConsultantProfile'));
const FindDoctor = lazy(() => import('../views/Book/FindDoctor'));
const Appointment = lazy(() => import('../views/Book/Appointment'));
const BookEmergency = lazy(() => import('../views/Book/Emergency'));
const BookAmbulance = lazy(() => import('../views/Book/Ambulance'));


//Departments
const Cardiology = lazy(() => import('../views/Departments/Medcine/Cardiology'));
const EndocrinologyMetabolism = lazy(() => import('../views/Departments/Medcine/EndocrinologyMetabolism'));
const ICU = lazy(() => import('../views/Departments/Medcine/ICU'));
const Medicine = lazy(() => import('../views/Departments/Medcine/Medicine'));
const Nephrology = lazy(() => import('../views/Departments/Medcine/Nephrology'));
const NeuroMedicine = lazy(() => import('../views/Departments/Medcine/NeuroMedicine'));
const NutritionDietetic = lazy(() => import('../views/Departments/Medcine/NutritionDietetic'));
const Oncology = lazy(() => import('../views/Departments/Medcine/Oncology'));
const Pediatrics = lazy(() => import('../views/Departments/Medcine/Pediatrics'));
const PediatricsHematologyOncology = lazy(() => import('../views/Departments/Medcine/PediatricsHematologyOncology'));

const PhysicalMedicineRehabilitation = lazy(() => import('../views/Departments/Medcine/PhysicalMedicineRehabilitation'));
const Psychiatry = lazy(() => import('../views/Departments/Medcine/Psychiatry'));

const CardioThoracicSurgery = lazy(() => import('../views/Departments/Surgery/CardioThoracicSurgery'));
const Dermatology = lazy(() => import('../views/Departments/Surgery/Dermatology'));
const ENT = lazy(() => import('../views/Departments/Surgery/ENT'));
const GeneralSurgery = lazy(() => import('../views/Departments/Surgery/GeneralSurgery'));
const NeuroSurgery = lazy(() => import('../views/Departments/Surgery/NeuroSurgery'));
const Ophthalmology = lazy(() => import('../views/Departments/Surgery/Ophthalmology'));
const OrthopedicSurgery = lazy(() => import('../views/Departments/Surgery/OrthopedicSurgery'));
const Urology = lazy(() => import('../views/Departments/Surgery/Urology'));

const Emergency = lazy(() => import('../views/Departments/Others/Emergency'));
const Anesthesiology = lazy(() => import('../views/Departments/Others/Anesthesiology'));
const CardiacAnaesthesiologyCICU = lazy(() => import('../views/Departments/Surgery/CardiacAnaesthesiologyCICU'));

const LaboratoryServices = lazy(() => import('../views/Departments/Investigation/LaboratoryServices'));
const ObstetricsGynecology = lazy(() => import('../views/Departments/Gynecology/ObstetricsGynecology'));

const Hematology  = lazy(() => import('../views/Departments/Others/Hematology'));
const TransfusionMedicine  = lazy(() => import('../views/Departments/Others/TransfusionMedicine'));
const RadiologyImaging = lazy(() => import('../views/Departments/Investigation/RadiologyImaging'));

const ConservativeDentistry = lazy(() => import('../views/Departments/Dental/ConservativeDentistry'));
const OralMaxilloFacialSurgery = lazy(() => import('../views/Departments/Dental/OralMaxilloFacialSurgery'));
const Orthodontics = lazy(() => import('../views/Departments/Dental/Orthodontics'));
const PediatricDentistry = lazy(() => import('../views/Departments/Dental/PediatricDentistry'));
const Periodontology = lazy(() => import('../views/Departments/Dental/Periodontology'));
const Prosthodontics = lazy(() => import('../views/Departments/Dental/Prosthodontics'));
const Dentistry = lazy(() => import('../views/Departments/Dental/Dentistry'));

//Services
const Ambulance = lazy(() => import('../views/Services/Ambulance'));
const NewsNotice = lazy(() => import('../views/NewsMedia/NewsNotice'));
const ViewAllNotices = lazy(() => import('../views/NewsMedia/ViewAllNotices'));
const ArticlesNews = lazy(() => import('../views/NewsMedia/ArticlesNews'));
const Events = lazy(() => import('../views/NewsMedia/Events'));

// Services Menu
const ATM = lazy(() => import('../views/Services/ATM'));
const BloodBank = lazy(() => import('../views/Services/BloodBank'));
const Cafeteria = lazy(() => import('../views/Services/Cafeteria'));
const CarParking = lazy(() => import('../views/Services/CarParking'));
const DayCare = lazy(() => import('../views/Services/DayCare'));
const GuestHouse = lazy(() => import('../views/Services/GuestHouse'));
const Gym = lazy(() => import('../views/Services/Gym'));
const MedicalRecord = lazy(() => import('../views/Services/MedicalRecord'));
const OpticalShop = lazy(() => import('../views/Services/OpticalShop'));
const Pharmacy = lazy(() => import('../views/Services/Pharmacy'));

//News & Media
const ArticlesNewsDetails = lazy(() => import('../views/NewsMedia/ArticlesNewsDetails'));
const EventDetails = lazy(() => import('../views/NewsMedia/EventDetails'));
const Photo = lazy(() => import('../views/NewsMedia/Gallery/Photo'));
const Video = lazy(() => import('../views/NewsMedia/Gallery/Video'));
const HealthPackages = lazy(() => import('../views/HealthPackages/HealthPackages'));
const Contact = lazy(() => import('../views/Contact/Contact'));
const Error404 = lazy( () => import('../errors/Error404'))

const Offices = lazy( () => import('../views/QuickMenu/Offices'))
const EmployeeProfile = lazy( () => import('../views/QuickMenu/EmployeeProfile'))
const Career = lazy( () => import('../views/QuickMenu/Career'))
const SiteMap = lazy( () => import('../views/QuickMenu/SiteMap'))

const AppRoutes = () => {
  const {loading, setLoading } =  useContext(RootContext)
  useEffect( () => {
    setLoading(false);
  },[setLoading])

  return loading ? <Loader /> : (
    <Suspense fallback={<Loader />}>
      <ScrollToTop>
        <Routes>
          <Route path="/*" element={<FrontendLayout />}>
              <Route index element={<Home />}/>
              <Route path="ipd-opd" element={<IPDOPD />}/>
              <Route path="view-pdf/:id" element={<PDFView />}/>

              <Route path="speciality/*" element={<SubMenuLayout />}>
                  <Route path="cancer-center" element={<CancerCenter />}/>
                  <Route path="dental-unit" element={<DentalUnit />}/>
                  <Route path="cardiac-surgery" element={<CardiacSurgerySpeciality/>}/>
                  <Route path="neuro-surgery" element={<NeurosurgerySpeciality/>}/>
                  <Route path="cardiology" element={<CardiologySpeciality/>}/>
    
              </Route>

              <Route path="testimonial/" element={<SubMenuLayout />}>
                <Route path=":id" element={<TestimonialDetailsLayout />}/>
              </Route>     
              <Route path="about/" element={<SubMenuLayout />}>
                   <Route path="founder-chairman" element={<Founder />}/>
                   <Route path="board-of-trustees/*" element={<SubMenuLayout />}>
                      <Route index element={<BOT />} />
                      <Route path=":id" element={<BOTProfile/>} />
                   </Route> 
                    <Route path="messages/" element={<SubMenuLayout />}>
                       <Route path="director-bot" element={<BOTDirector />}/>
                       <Route path="director-kyamch" element={<KYAMCHDirector />}/>
                    </Route>
                    <Route path="mission-vision" element={<MissionVision />}/>
                    <Route path="certificates-achievements/*" element={<SubMenuLayout />}>
                      <Route index element={<CertificatesAchievements />} />
                      <Route  path=":id/*" element={<SubMenuLayout />}>
                        <Route index element={<AcheivementList />} />
                        <Route path=":id" element={<AcheivementListDetails />} />
                      </Route>
                   </Route>  
              </Route>
              <Route path="doctors/" element={<SubMenuLayout />}>
                <Route index element={<Consultants />}/>
                <Route path=":id" element={<ConsultantProfile/>} />
                <Route path="find-doctors" element={<FindDoctor/>} />
              </Route>
              <Route path="book/" element={<SubMenuLayout />}>
                <Route path='appointment' element={<Appointment />}/>
                <Route path='Emergency' element={<BookEmergency />}/>
                <Route path='Ambulance' element={<BookAmbulance />}/>
              </Route>
              <Route path="departments/" element={<SubMenuLayout />}>
                  <Route path="cardiology" element={<Cardiology />}/> 
                  <Route path="endocrinology-metabolism" element={<EndocrinologyMetabolism />}/> 
                  <Route path="ICU" element={<ICU />}/> 
                  <Route path="medicine" element={<Medicine />}/> 
                  <Route path="nephrology" element={<Nephrology />}/> 
                  <Route path="neuro-medicine" element={<NeuroMedicine />}/> 
                  <Route path="nutrition-dietetic" element={<NutritionDietetic />}/> 
                  <Route path="oncology" element={<Oncology />}/> 
                  <Route path="pediatrics" element={<Pediatrics />}/> 
                  <Route path="pediatric-hematology-oncology" element={<PediatricsHematologyOncology />}/> 

                  <Route path="physical-medicine-rehabilitation" element={<PhysicalMedicineRehabilitation />}/> 
                  <Route path="psychiatry" element={<Psychiatry />}/> 
                  <Route path="cardio-thoracic-surgery" element={<CardioThoracicSurgery />}/> 
                  <Route path="dermatology" element={<Dermatology />}/> 
                  <Route path="ENT" element={<ENT />}/> 
                  <Route path="general-surgery" element={<GeneralSurgery />}/> 
                  <Route path="neuro-surgery" element={<NeuroSurgery />}/> 
                  <Route path="ophthalmology" element={<Ophthalmology />}/> 
                  <Route path="orthopedic-surgery" element={<OrthopedicSurgery />}/> 
                  <Route path="urology" element={<Urology />}/> 
                  
                  <Route path="emergency" element={<Emergency />}/> 
                  <Route path="anesthesiology" element={<Anesthesiology />}/> 
                  <Route path="cardiac-anaesthesiology-CICU" element={<CardiacAnaesthesiologyCICU />}/> 
                  
                  <Route path="laboratory-services" element={<LaboratoryServices />}/> 
                  <Route path="obstetrics-gynecology" element={<ObstetricsGynecology />}/> 
                  <Route path="transfusion-medicine" element={<TransfusionMedicine />}/> 
                  <Route path="hematology" element={<Hematology />}/> 
                  <Route path="radiology-imaging" element={<RadiologyImaging />}/> 

                  <Route path="conservative-dentistry" element={<ConservativeDentistry />}/> 
                  <Route path="oralMaxillo-facial-surgery" element={<OralMaxilloFacialSurgery />}/> 
                  <Route path="orthodontics" element={<Orthodontics />}/> 
                  <Route path="pediatric-dentistry" element={<PediatricDentistry />}/> 
                  <Route path="periodontology" element={<Periodontology />}/> 
                  <Route path="prosthodontics" element={<Prosthodontics />}/> 
                  <Route path="dentistry" element={<Dentistry />}/> 
              </Route>

              <Route path="services/" element={<SubMenuLayout />}>
                  <Route path="ambulance" element={<Ambulance />}/> 
                  <Route path="ATM" element={<ATM />}/> 
                  <Route path="blood-bank" element={<BloodBank />}/> 
                  <Route path="food-court" element={<Cafeteria />}/> 
                  <Route path="car-parking" element={<CarParking />}/> 
                  <Route path="day-care" element={<DayCare />}/> 
                  <Route path="guest-house" element={<GuestHouse />}/> 
                  <Route path="gym" element={<Gym />}/> 
                  <Route path="medical-record" element={<MedicalRecord />}/> 
                  <Route path="opticalShop" element={<OpticalShop />}/> 
                  <Route path="pharmacy" element={<Pharmacy />}/> 
              </Route>

              <Route path="news-media/" element={<SubMenuLayout />}>
                  <Route path="news-notice" element={<NewsNotice />}/> 
                  <Route path="view-all-notices" element={<ViewAllNotices />}/> 
                  
                  <Route path="articles-news/*" element={<SubMenuLayout />}>
                     <Route index element={<ArticlesNews />}/> 
                     <Route path=":id" element={<ArticlesNewsDetails />}/> 
                  </Route>

                  <Route path="events/*" element={<SubMenuLayout />}>
                     <Route index element={<Events />}/> 
                     <Route path=":id" element={< EventDetails/>}/> 
                  </Route>

                  <Route path="gallery/*" element={<SubMenuLayout />}>
                     <Route path="photo" element={<Photo />}/> 
                     <Route path="video" element={<Video />}/> 
                  </Route>
              </Route>

              <Route path="health-check-up-packages" element={<HealthPackages />}/>
              <Route path="contact" element={<Contact />}/>
              
              <Route path="footer/*" element={<SubMenuLayout />}>
                   <Route path="offices/*" element={<SubMenuLayout />}>
                     <Route index element={<Offices />}/> 
                     <Route path=":id" element={<EmployeeProfile />}/> 
                  </Route>
                  <Route path="career" element={<Career />}/> 
                  <Route path="site-map" element={<SiteMap />}/> 
              </Route>
              <Route path="*" element={<Error404 />} />
          </Route>
        </Routes>
    </ScrollToTop>
  </Suspense>
  )
}
export default AppRoutes