export const HealthServicesData = [
    {
        img: 'mri.jpg',
        caption: 'MRI',
        content: "KYAMCH offers advanced MRI for accurate diagnosis and treatment. State-of-the-art facilities aid in detailed imaging of medical conditions."
    },
    {
        img: 'x-ray.jpg',
        caption: 'X-Ray',
        content: 'We provide top-tier X-ray diagnostic services. Our cutting-edge X-ray technology and experienced radiologists ensure accurate and detailed imaging.'
    },
    {
        img: 'ct-scan.jpg',
        caption: 'CT',
        content: 'Explore our cutting-edge CT scan facility, providing precise diagnostic imaging to enhance your healthcare experience. Fast, accurate, and compassionate care at your service.'
    },
    {
        img: 'ultrasound.jpg',
        caption: 'Ultrasound',
        content: 'Experience the power of ultrasound technology. Our state-of-the-art ultrasound services provide clear imaging for accurate diagnoses and exceptional patient care.'
    },
    {
        img: 'mammogram.jpg',
        caption: 'Mammography',
        content: "Trust our advanced mammography services. Our compassionate team delivers accurate breast screenings, promoting early detection & women's health"
    },
    {
        img: 'echo.png',
        caption: 'ECHO ',
        content: 'Elevate cardiac care with our Echocardiography (ECHO) services. Precise imaging, expert analysis, and compassionate care ensure your heart health is in capable hands'
    },
    {
        img: 'ecg.jpg',
        caption: 'ECG ',
        content: 'Discover the simplicity and importance of ECG tests. Our facility offers quick, accurate electrocardiograms to monitor heart health, ensuring your peace of mind.'
    },
    {
        img: 'eeg.jpeg',
        caption: 'EEG ',
        content: 'Unlock the ease and significance of EEG tests. Experience swift, precise electroencephalograms at our facility, monitoring brain activity for peace of mind.'
    },
    {
        img: 'ett.jpg',
        caption: 'ETT ',
        content: 'Enhance cardiac evaluation with Exercise Treadmill Testing (ETT). Our facility provides stress tests to assess heart function, promoting comprehensive heart health.'
    },
    {
        img: 'audiometry.png',
        caption: 'Audiometry ',
        content: 'Embark on discovering the art of swift and accurate hearing tests at our facility, ensuring your auditory health and peace of mind are in perfect harmony'
    },
    {
        img: 'default.png',
        caption: 'Spirometry ',
        content: 'Embrace the science of spirometry with us! Our facility offers efficient and accurate lung function tests, ensuring your respiratory health and providing peace of mind.'
    },
    {
        img: 'default.png',
        caption: 'BMD',
        content: 'Step into the realm of bone health with our cutting-edge bone mineral density tests. Swift and precise, we ensure your skeletal strength, providing a foundation for health.'
    },
]