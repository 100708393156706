import React from 'react'
import { NavLink } from 'react-router-dom'

const OurSpecialityLayout = (props) => {
  return (
    <div className="col-md-4" data-aos="fade-up">
        <div className="m-2">
            <div className="speciality-box border text-center bg-white p-4" style={{borderRadius: '15px'}}>
                <div className="">
                    <img src={'/asset/img/speciality/'+props.img} alt={props.specialityName} className='d-block mx-auto rounded-circle'/>
                </div>
                <h5 className='py-2'>{props.specialityName}</h5>
                <small>{props.description}</small>
                <br />
                <NavLink to={props.route} className='text-decoration-none text-success'>Read More...</NavLink>
            </div>
        </div>
    </div>
  )
}

export default OurSpecialityLayout