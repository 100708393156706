import React from 'react'
import './NewsCardBoxLayout.css'
import { NavLink } from 'react-router-dom'
import { formatDate } from '../utils/dateUtils'

const NewsCardBoxLayout = (props) => {
  return (
    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-2" data-aos="fade-up">
        <div className="shadow bg-white" id='news-card-box'>
            <div className="position-relative">
                <img src={process.env.REACT_APP_IMAGEBASE_URL+props.imgPath+props.img} alt="pic" className='p-1 w-100 h-auto' style={{maxWidth: '1920px'}}/>
                <div className='d-flex justify-content-center my-1'>
                    <span className='bg-success text-white fw-bold w-75 text-center rounded-pill'>{formatDate(props.at).slice(0,12)}</span>
                </div>
                <hr />
            </div>
            <div className="content px-1 text-center">
                <h6 className='fw-bold truncate'>{props.caption_text}</h6>
            </div>
            <div className="d-flex justify-content-center py-3" id='btn-read-more'>
                <NavLink className="btn btn-sm btn-outline-success" to={'/news-media/'+props.url+props.id}>
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="me-2 bi bi-plus-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                        </svg>
                    </span>
                    Read More
                </NavLink>
            </div>
        </div>
    </div>
  )
}

export default NewsCardBoxLayout