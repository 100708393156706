import React from 'react'
import { NavLink } from 'react-router-dom'

const PatientTestimonialLayout = (props) => {
  return (
    <div className="p-2" data-aos="fade-up">
    <div className="rounded-3 shadow bg-white py-4 px-2" id='testimonial-card-box'>
        <div className="img">
            <img src={process.env.REACT_APP_IMAGEBASE_URL+'testimonial/'+props.profileImg} alt={props.profileImg} className='w-100 mx-auto d-block' style={{maxWidth: '100px', height: '100px', borderRadius: '50%'}}/>  
        </div>

        <div className="content text-center">
            <div className="py-3">
               <article className='truncate'>{props.aboutPatient}</article>
            </div>
              <div className="d-flex justify-content-evenly align-items-center py-3">
                    <NavLink to={'testimonial/'+props.id} className='btn btn-sm btn-outline-success w-50 mx-2 rounded-pill'>Details</NavLink>     
              </div>
            <h6 className='fw-bold'>{props.patientName}</h6>
            <p>Age: {props.patientAge}</p>
        </div>
    </div>
</div>
  )
}

export default PatientTestimonialLayout