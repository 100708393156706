import React from 'react'
import { Col } from 'react-bootstrap'

const HospitalStatisticsLayout = (props) => {
  return (
    <Col xl={2} md={6}>
        <div className="text-center border-start box-hover border-end py-3" data-aos="fade-up">
            <div>
                <img src={props.imgLogo} alt={props.boxCaption} className='w-100' style={{maxWidth:'80px'}}/>
            </div>
            <h1>{props.totalNum}</h1>
            <h6>{props.boxCaption}</h6>
        </div>
   </Col>
  )
}

export default HospitalStatisticsLayout