const currentYear = new Date().getFullYear();
const startYear = 2004;
const yearsOfExperience = currentYear - startYear;

export const HospitalStatisticsData=[
   {
     imgLogo: '/asset/img/icon/doctor.png',
     totalNum: '200 +',
     boxCaption: 'TOTAL DOCTORS'
   },
  {
    imgLogo: '/asset/img/icon/employee.png',
    totalNum: '400 +',
    boxCaption: 'MEDICAL WORKERS'
  },
  {
    imgLogo: '/asset/img/icon/years.png',
    totalNum: `${yearsOfExperience} +`,
    boxCaption: 'YEARS OF EXPERIENCE'
  },
   {
        imgLogo: '/asset/img/icon/consultant-rooms.jpg',
        totalNum: '40 +',
        boxCaption: 'TOTAL DEPARTMENTS'
   },
   {
        imgLogo: '/asset/img/icon/patient-beds.png',
        totalNum: '600 + ',
        boxCaption: 'PATIENT BEDS'
    },
    {
        imgLogo: '/asset/img/icon/employee.png',
        totalNum: '2000 +',
        boxCaption: 'EMPLOYEES'
    }
   
];