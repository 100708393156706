import { useEffect, useState } from 'react'
import Heading from '../../layouts/Heading'
import ResizeScreenComposition from '../../layouts/ResizeScreenComposition'
import SectionComposition from '../../layouts/SectionComposition'
import { Row } from 'react-bootstrap'
import NewsCardBoxLayout from '../../layouts/NewsCardBoxLayout'
import api from '../../utils/axiosConfig'

const ArticleNewsView = () => {
    const [articleData, setArticleData] = useState([]);
    useEffect(()=> {
      api.get(`web-articles`).then((res) => {
        setArticleData(res.data)
      });
    },[])
  return (
    <ResizeScreenComposition bgColor="off-white">  
        <SectionComposition>
            <Heading headingTitle ="Articles and News" />
              <Row>
                  {
                      articleData.map((item, index)=> 
                      <NewsCardBoxLayout key={index} id={item.id} url="articles-news/" caption_text={item.caption_text} img={item.article_img} imgPath = "articles/"  at={item.article_at}/>
                      )
                  }
              </Row>
       </SectionComposition>
    </ResizeScreenComposition>
  )
}

export default ArticleNewsView