import { useState, useEffect } from 'react';
const ResizeScreenComposition = (props) => {
        // CSS State
        const [isWideScreen, setIsWideScreen] = useState(false);
    useEffect(() => {
        resizeScreen();
      }, []);
    const resizeScreen = () => {
          const handleScreenChange = (event) => {
            setIsWideScreen(event.matches);
          };
          const mediaQuery = window.matchMedia('(min-width: 1194px)');
          mediaQuery.addListener(handleScreenChange);
          handleScreenChange(mediaQuery);
          return () => {
            mediaQuery.removeListener(handleScreenChange);
          };
      }
  return (
    <div className={isWideScreen ? `container-fluid px-5 ${props.bgColor}` : `px-2 ${props.bgColor}`}>
         {props.children}
    </div>
  )
}

export default ResizeScreenComposition