import { useState,useEffect } from 'react';
import { BrowserRouter, NavLink } from 'react-router-dom';
import RootContext from './context/RootContext';
import AppRoutes from './routes/AppRoutes';
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';
// import { MessengerChat } from 'react-messenger-chat-plugin'

import OpenNewTab from './hooks/OpenNewTab';

function App() {

  const {openInNewTab} = OpenNewTab();

  //Use Context State
  const [loading, setLoading] = useState(true);
  const [year , setYear] = useState();

  const getFullYear = ()=> {
      setYear(new Date().getFullYear())
  }
  useEffect(() => {
    getFullYear();
    AOS.init({
        offset: 100, // offset (in px) from the original trigger point
        duration: 1500, // values from 0 to 3000, with step 50ms
        easing: "ease",
    });
}, []);

  return (
      <BrowserRouter>
        <RootContext.Provider
              value={{
                  loading,
                  setLoading,
                  year,
              }}
              >
            <AppRoutes />
            {/* <MessengerChat
              pageId="258293777368688" // Replace with your page ID
              themeColor={"#03b244"}
              loggedInGreeting="Hello, how can I help you today?"
              loggedOutGreeting="Recommend to log in to chat for feedback."
            /> */}
            <div className='fixed-bottom text-danger right-100 p-3'>
               <NavLink to="" title='As-salamu alaykum ! How can I help you today? (Service available from 7 AM - 5.30 PM)' className='' onClick={(e) => openInNewTab('https://wa.me/+88001915478118')}  target="_blank"><img src='/asset/img/icon/hotline.gif' alt='whatsapp icon' width='80'/></NavLink>
            </div>
            
        </RootContext.Provider>  
      </BrowserRouter>
  );
}

export default App;
