import React, { lazy, Suspense, useEffect, useState } from 'react';
import {Carousel,Row} from 'react-bootstrap';
import Heading from '../../layouts/Heading';
import Marquee from "react-fast-marquee";
import SectionComposition from '../../layouts/SectionComposition';
import PageTitle from '../../hooks/PageTitle';
import api from '../../utils/axiosConfig';
import ResizeScreenComposition from '../../layouts/ResizeScreenComposition';
import PatientTestimonialLayout from '../../layouts/PatientTestimonialLayout';
import LogoCarouselLayout from '../../layouts/LogoCarouselLayout';
import { SisterConcernData } from './SisterConcernData';
import HospitalStatisticsLayout from '../../layouts/HospitalStatisticsLayout';
import { HospitalStatisticsData } from './HospitalStatisticsData';
import ServicesLayout from '../../layouts/ServicesLayout';
import {ServicesData}  from './ServicesData';
import { NavLink } from 'react-router-dom';
import { HealthServicesData } from './HealthServicesData';
import BoxCarouselComposition from '../../layouts/BoxCarouselComposition';
import HealthDiagnosticLayout from '../../layouts/HealthDiagnosticLayout';
import OurSpecialityData from './OurSpecialityData.json'
import OurSpecialityLayout from '../../layouts/OurSpecialityLayout';
import NoDataFoundLayout from '../../layouts/NoDataFoundLayout'
import ArticleNewsView from '../NewsMedia/ArticleNewsView';

const LazyImage = lazy(() => import('../../redux/LazyImg'));

const Home = () => {


    const [carouselData, setCarouselData] = useState([]);
    const [patientTestimonialData, setPatientTestimonialData] = useState([]);
    useEffect(() => {
        api.get("web-slider-content").then((res) => {
            setCarouselData(res.data)
        });

        api.get("web-patient-testimonial").then((res) => {
            setPatientTestimonialData(res.data)
        });
      }, []);

      

  return (
    <main>
         <style>
        {`
         .caption-container{
            position: absolute;
            display: flex;
            align-items: center;
            height: 100%;
         }
        
          @media screen and (max-width: 1280px) {
            .caption-container{
               display: none !important;
            }
         }

          .caption {
            animation: captionLeftRight 1s forwards;
          }

          @keyframes captionLeftRight {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
                transform: translateY(0) translateX(0); 
            }
          }
        `}
      </style>

         <PageTitle title="Home" />

         <Carousel pause={false} fade>
            { 
              carouselData.length === 0 ? (
                <NoDataFoundLayout errorCaption="No banner or slider is uploaded yet" />
              ) : (carouselData.map( (item, index) => {
                const translateXValue = (item.caption_text_align === 'flex-start') ? '-50px' : '50px'; 
                return (
                <Carousel.Item key={index}>
                         <Suspense fallback={<div className='text-center'>Loading...</div>}>
                            <LazyImage src={process.env.REACT_APP_IMAGEBASE_URL+'carousel/'+item.img} alt={item.img}/>
                        </Suspense>
                        <Carousel.Caption className='caption-container' style={{justifyContent:`${item.caption_text_align}`}}>
                            <div className='caption w-50 text-center' style={{ transform: `translateY(-50px) translateX(${translateXValue})` }}>
                                 <span dangerouslySetInnerHTML={{__html: item.caption_text}} />
                            </div>
                        </Carousel.Caption>
                   </Carousel.Item>
                 )
                }))
            }
         </Carousel>
         
         <ResizeScreenComposition bgColor="off-white">  
            <SectionComposition>
                <div className="row">
                    {
                        ServicesData.map((item, index)=>
                            <ServicesLayout key={index} route={item.route} logo={item.logo} caption={item.caption}/>
                        )
                    }
                </div>
            </SectionComposition>
         </ResizeScreenComposition>   

  
          
         <ResizeScreenComposition bgColor="off-white">  
            <SectionComposition>
                <Heading headingTitle ="Our Special Center" />
                 <div className="row">
                     {
                        OurSpecialityData.map((item, index)=>
                          <OurSpecialityLayout key={index} route={item.route} specialityName={item.specialityName} img={item.img} description={item.description}/>
                        )
                     }
                       
                 </div>
            </SectionComposition>
         </ResizeScreenComposition>   

         <ResizeScreenComposition bgColor="">  
            <SectionComposition>
                <Heading headingTitle ="Diagonostic Services" />
                    <BoxCarouselComposition>
                        {
                            HealthServicesData.map((item, index) => 
                               <HealthDiagnosticLayout key={index} img={item.img} caption={item.caption} content={item.content}/>
                            )
                        }
                    </BoxCarouselComposition>
            </SectionComposition>
         </ResizeScreenComposition>   
        {/* Article and News Section Start */}
        <ResizeScreenComposition bgColor="">  
            <SectionComposition>
                 <div className="row">
                    <div className="col-md-4">
                         <img src="/asset/img/header/home-img.svg" alt="home-img" className='w-100 p-0 m-0'/>
                    </div>
                    <div className="col-md-8 text-center">
                       {/* <Heading headingTitle = "Executive Health Check-up" /> */}
                       <div data-aos="fade-up">
                            <div className="py-5">
                                <h4 className='text-deep-blue'>Executive Health Check-up Packages</h4>
                            </div>
                            <h5 className='text-success lh-base'>Health check-up packagesare designed to promote good health and facilitate early detection of health problems.</h5>
                       </div>
  
                        <div className="py-5" data-aos="zoom-in">
                           <NavLink to='/health-check-up-packages' className='btn btn-sm btn-outline-success w-50 rounded-pill mx-auto d-block'>More</NavLink>
                        </div>
                    </div>
                 </div>
             </SectionComposition>
        </ResizeScreenComposition>        
        <ArticleNewsView />
        {/* Article and News Section Start */}
                 <ResizeScreenComposition bgColor="">  
                   <SectionComposition>
                    <Row>
                        {
                            HospitalStatisticsData.map((item, index)=> (
                                <HospitalStatisticsLayout key={index} imgLogo={item.imgLogo} totalNum={item.totalNum} boxCaption={item.boxCaption}/>
                            ))
                            
                        }
                    </Row>
                    </SectionComposition>
                </ResizeScreenComposition>
        {/* Article and News Section End */}
     {/* Article and News Section Start */}
        <ResizeScreenComposition bgColor="off-white">  
            <SectionComposition>
                <Heading headingTitle = "Patient Testimonial" />
                   <BoxCarouselComposition>
                     {
                        patientTestimonialData.map((item, index) => 
                            <PatientTestimonialLayout key={index} id={item.id} profileImg={item.profile_img} aboutPatient={item.about_patient} patientName={item.patient_name} patientAge={item.patient_age}/>
                        )
                     }
                    </BoxCarouselComposition>
                </SectionComposition>
                </ResizeScreenComposition>
        {/* Article and News Section End */}
     {/* Article and News Section Start */}
     <ResizeScreenComposition bgColor="">  
        <SectionComposition>
            <Heading headingTitle = "Associated Organization" />
                    <Marquee speed={100} pauseOnHover={true} className="mt-1">
                    {
                            SisterConcernData.map( (item, index)=> {
                                return <LogoCarouselLayout key={index} imgsrc={item.img}  alt={item.alt} pageLink={item.url} />
                            })
                    }
                </Marquee>
            </SectionComposition>
    </ResizeScreenComposition>
    {/* Article and News Section End */}
    </main>
  )
}
export default Home