export const SisterConcernData = [
    {
        img: "/asset/img/sister-concern/kyau.png",
        alt: "KYAU",
        url: "https://kyau.edu.bd/",
    },
    {
        img: "/asset/img/sister-concern/drug.png",
        alt: "drug",
        url: "https://www.drug-international.com/",
    },
    {
        img: "/asset/img/sister-concern/kyamc.png",
        alt: "kyamc",
        url: "https://kyamc.edu.bd/",
    },
    {
        img: "/asset/img/sister-concern/ati.png",
        alt: "ati",
        url: "https://www.atilimited.net/",
    },
    {
        img: "/asset/img/sister-concern/ati-ceramics.png",
        alt: "aticeramics",
        url: "https://aticeramics.com/",
    },
    {
        img: "/asset/img/sister-concern/harnest.png",
        alt: "harnest",
        url: "https://harnest.com/",
    },
    {
        img: "/asset/img/sister-concern/kyanc.png",
        alt: "kyanc",
        url: "http://kyanc.edu.bd/",
    },
    {
        img: "/asset/img/sister-concern/kyalsc.png",
        alt: "kyalsc",
        url: "https://kyalsc.edu.bd/",
    },
]