import {useState, useEffect} from 'react'
import { NavLink, useLocation } from 'react-router-dom';
import "./Header.css"
import {Container, Row, Col} from 'react-bootstrap';
import OpenNewTab from '../../hooks/OpenNewTab'


const Header = () => {
  const [navigationMenu, setNavigationMenu] = useState('navigation-menu');
  const {openInNewTab} = OpenNewTab();

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        autoDisplay: false,
      },
      'google_translate_element'
    );
  };

  const location = useLocation();
  const handleToggleButton = (e) =>{
    e.preventDefault();
    if (navigationMenu === "navigation-menu") {
        setNavigationMenu("navigation-menu responsive")
    } else {
        setNavigationMenu("navigation-menu")
    }
  }
  const Sticky = () => {
    if(window.scrollY > 50){
    //    setNavigationMenu("navigation-menu");
       document.getElementById('header-container').classList.add('fixed-top');
       document.getElementById('header-top-info').classList.add('header-top-info');
    }else{
      document.getElementById('header-container').classList.remove('fixed-top');
      document.getElementById('header-top-info').classList.remove('header-top-info');
    }
  }
  useEffect(() => {
    setNavigationMenu("navigation-menu")
    window.addEventListener('scroll', Sticky)

    if (!window.googleTranslateElementInit) {
      var addScript = document.createElement('script');
      addScript.setAttribute(
        'src',
        '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
      );
      document.body.appendChild(addScript);
      window.googleTranslateElementInit = googleTranslateElementInit;
    }
  }, [location]);


  return (
    <header id="header-container" className='shadow'>
        {/* Top Bar Start */}
        <div className="border-bottom" id='header-top-info'>
            <Container>
                <Row className='text-center'>
                    <Col xl={4}>
                      <div className="d-flex justify-content-center align-items-center h-100">
                          <button className='btn border text-teal-dark fw-bold rounded-pill'>
                              <img src="/asset/img/icon/hotline.gif" alt="hotline" className='icon'/> HotLine || <NavLink to="#"  className='text-decoration-none text-danger'  onClick={(e) => openInNewTab('https://wa.me/+8801716291681')}>10621</NavLink>
                          </button>
                      </div>
                    </Col>
                    <Col xl={4}>
                      <div>
                        <span className='text-rainbow-animation'>World Class Hospital || Affordable Cost</span>
                           <br/>
                         <small>OPD-365 Days. IPD-24x7</small>
                      </div>
                    </Col>
                    <Col xl={3}>
                      <div className="d-flex justify-content-center align-items-center" id='location'>
                          <img src="/asset/img/icon/map.gif" alt="MapIcon" className='icon'/>
                          <a className='text-teal-dark fw-bold text-decoration-none' href="/contact" title='Click to see the location in google map'>Enayetpur, Chouhali, Sirajganj - 6751, Bangladesh</a>
                      </div>
                    </Col>
                    <Col xl={1} className='px-3'>
                      <div id="google_translate_element"> 
                      
                      </div>
                    </Col>
                </Row>

            </Container>
        </div>
        {/* Top Bar End */}

        <Container fluid className='border-bottom'>
            <Row>
              <Col xl={4} className={navigationMenu === 'navigation-menu' ? '' : 'border-bottom'}>
                  <div className="d-flex justify-content-between align-items-center" id='navbar-brand-container'>
                     <div className='d-flex justify-content-center align-items-center'>
                        <NavLink to='/'><img src="/asset/img/logo/kyamch-logo-big.png" alt="KYAMCH LOGO" className='logo px-1' /></NavLink>
                        <h4 className='text-center title text-teal-dark m-0 p-0'><NavLink to="/" className='text-decoration-none text-teal-dark'>Khwaja Yunus Ali Medical <br/> College & Hospital</NavLink></h4>
                     </div>
                    <div className='bar' onClick={handleToggleButton}>
                        {
                            navigationMenu === 'navigation-menu' ?
                            (
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#bb3300" className="bi bi-list" viewBox="0 0 16 16" title="Click to view menu">
                              <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
                            </svg>
                            ):
                            (
                              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-x-octagon text-danger" viewBox="0 0 16 16">
                                <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z"/>
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                              </svg>
                            )
                        }
                    </div>
                  </div>
              </Col>

              <Col xl={8}>
                <nav className={navigationMenu} id="navigationMenu">
                  <NavLink to="/" className={location.pathname === "/" ? "active-menu" : ""}>HOME</NavLink>
                  <div className="dropdown-container">
                      <button className="dropbtn">ABOUT
                      </button>
                      <div className="dropdown-content">
                          <NavLink to="/about/founder-chairman">Founder Chairman</NavLink>
                          <NavLink to="/about/board-of-trustees" className=''>Board Of Trustees</NavLink>
                          <div className="sub-dropdown-container">
                              <button className="dropbtn">Messages 
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                  <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                </svg>                     
                              </button>
                              <div className="sub-dropdown-content">
                                  <NavLink to="about/messages/director-bot">Director of BOT</NavLink>
                                  <NavLink to="about/messages/director-kyamch">Director of KYAMCH</NavLink>
                              </div>
                          </div>
                          <NavLink to="/about/mission-vision">Mission, Vision, Values</NavLink>
                          <NavLink to="/about/certificates-achievements">Certificates and Achievements</NavLink>
                      </div>
                  </div>
                  <NavLink to="doctors" className={location.pathname === "/doctors" ? "active-menu" : ""}>DOCTORS</NavLink>
                  <div className="dropdown-container">
                      <button className="dropbtn">DEPARTMENTS 
                      </button>
                      <div className="mega-dropdown-content" id='mega-dropdown-content'>
                          <div className="header text-center bg-success text-white py-1">
                              <h4>ALL DEPARTMENTS INFORMATION</h4>
                          </div>
                          <div className="mega-row">
                              <div className="mega-column">
                                  <h5>MEDICINE & ALLIED</h5>
                                  <NavLink to="/departments/cardiology" target='_parent'>Cardiology</NavLink> 
                                  {/* <NavLink to="#" target='_parent'>Interventional Cardiology</NavLink>  */}
                                  
                                  <NavLink to="/departments/endocrinology-metabolism" target='_parent'>Endocrinology & Metabolism</NavLink> 
                                  <NavLink to="/departments/ICU" target='_parent'>General ICU</NavLink> 
                                  {/* <NavLink to="/departments/ICU" target='_parent'>Gastroenterology</NavLink>  */}
                                  <NavLink to="/departments/hematology" target='_parent'>Hematology</NavLink> 
                                  {/* <NavLink to="#" target='_parent'>Neonatal ICU</NavLink>    */}
                                  <NavLink to="/departments/medicine" target='_parent'>Medicine</NavLink>   
                                  
                                  <NavLink to="/departments/nephrology" target='_parent'>Nephrology</NavLink>   
                                  <NavLink to="/departments/neuro-medicine" target='_parent'>Neuro Medicine</NavLink>   
                                  <NavLink to="/departments/nutrition-dietetic" target='_parent'>Nutrition & Dietetic</NavLink> 

                                  <NavLink to="/departments/oncology" target='_parent'>Oncology</NavLink>   
                                  <NavLink to="/departments/pediatrics" target='_parent'>Pediatrics</NavLink>  
                                  <NavLink to="/departments/pediatric-hematology-oncology" target='_parent'>Pediatric Hematology and Oncology</NavLink>  
                                  <NavLink to="/departments/physical-medicine-rehabilitation" target='_parent'>Physical Medicine & Rehabilitation</NavLink>   
                                  <NavLink to="/departments/psychiatry" target='_parent'>Psychiatry</NavLink>   
                                  <NavLink to="#" target='_parent'>Pulmonology</NavLink>   
                                  <NavLink to="/departments/transfusion-medicine" target='_parent'>Transfusion Medicine</NavLink> 

                              </div>
                              <div className="mega-column">
                                  <h5>SURGERY & ALLIED</h5>
                                  <NavLink to="/departments/anesthesiology" target='_parent'>Anesthesiology</NavLink> 
                                  <NavLink to="/departments/cardiac-anaesthesiology-CICU" target='_parent'>Cardiac Anaesthesiology & CICU</NavLink> 
                                  <NavLink to="/departments/cardio-thoracic-surgery" target='_parent'>Cardiovascular & Thoracic Surgery</NavLink> 
                                  <NavLink to="/departments/dermatology" target='_parent'>Dermatology</NavLink> 
                                  <NavLink to='departments/emergency'>Emergency & Causality</NavLink>
                                  <NavLink to="/departments/ENT" target='_parent'>ENT (Ear Nose Throat) & Head Neck Surgery</NavLink> 
                                  <NavLink to="/departments/general-surgery" target='_parent'>General Surgery</NavLink> 
                                  <NavLink to="/departments/neuro-surgery" target='_parent'>Neuro Surgery</NavLink> 
                                  <NavLink to="/departments/ophthalmology" target='_parent'>Ophthalmology</NavLink> 
                                  <NavLink to="/departments/orthopedic-surgery" target='_parent'>Orthopedic Surgery</NavLink> 
                                  <NavLink to="/departments/urology" target='_parent'>Urology</NavLink> 
                              </div>
                              <div className="mega-column">
                                  <h5>GYNAECOLOGY & OBS</h5>
                                  <NavLink to="/departments/obstetrics-gynecology" target='_parent'>Obstetrics & Gynecology</NavLink> 
                                
                                  <p style={{color: '#198754'}}>INVESTIGATION</p>
                                  <NavLink to="/departments/laboratory-services" target='_parent'>Laboratory Services</NavLink> 
                                  <NavLink to="/departments/radiology-imaging" target='_parent'>Radiology & Imaging</NavLink> 
                              </div>
                              <div className="mega-column">
                                  <h5>DENTAL UNIT</h5>
                                  <NavLink to="/departments/conservative-dentistry" target='_parent'>Conservative Dentistry</NavLink> 
                                  <NavLink to="/departments/oralMaxillo-facial-surgery" target='_parent'>Oral & Maxillo-Facial Surgery</NavLink> 
                                  <NavLink to="/departments/orthodontics" target='_parent'>Orthodontics</NavLink> 
                                  <NavLink to="/departments/prosthodontics" target='_parent'>Prosthodontics</NavLink> 
                                  <NavLink to="/departments/pediatric-dentistry" target='_parent'>Pediatric Dentistry</NavLink> 
                                  <NavLink to="/departments/periodontology" target='_parent'>Periodontology</NavLink> 
                                  <NavLink to="/departments/dentistry" target='_parent'>Dentistry</NavLink> 
                              </div>
                          </div>
                      </div>
                  </div>

                  <div className="dropdown-container">
                      <button className="dropbtn">SERVICES
                      </button>
                      <div className="dropdown-content">

                          <NavLink to="/services/ambulance">Ambulance</NavLink>
                          <NavLink to="/services/ATM">ATM</NavLink>
                          <NavLink to="/services/blood-bank">Blood Bank</NavLink>
                          <NavLink to="/services/food-court">Food Court</NavLink>
                          <NavLink to="/services/car-parking">Car Parking</NavLink>
                          <NavLink to="/services/day-care">Day Care</NavLink>
                          <NavLink to="/services/gym">Gym</NavLink>
                          <NavLink to="/services/guest-house">Guest House</NavLink>
                          <NavLink to="/services/medical-record">Medical Record</NavLink>
                          <NavLink to="/services/opticalShop">Optical Shop</NavLink>
                          <NavLink to="/services/pharmacy">Pharmacy</NavLink>
                      </div>
                  </div>

                  <div className="dropdown-container">
                      <button className="dropbtn">NEWS & MEDIA
                      </button>
                      <div className="dropdown-content">
                          <NavLink to="news-media/news-notice">News-Notices</NavLink>
                          <NavLink to="news-media/articles-news">Articles and News</NavLink>
                          <NavLink to="news-media/events">Events & Programs</NavLink>
                          <div className="sub-dropdown-container">
                              <button className="dropbtn">Gallery 
                                   <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                      <path fill-ule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>  
                              </button>
                              <div className="sub-dropdown-content">
                                  <NavLink to="news-media/gallery/photo">Photo Gallery</NavLink>
                                  <NavLink to="news-media/gallery/video">Video Gallery</NavLink>
                              </div>
                          </div>
                      </div>
                  </div>
                  <NavLink to="health-check-up-packages" className={location.pathname === "/health-check-up-packages" ? "active-menu" : ""}>HEALTH CHECK UP PACKAGES</NavLink>        
                  <NavLink to="contact" className={location.pathname === "/contact" ? "active-menu" : ""}>CONTACT</NavLink>
                </nav>
              </Col>
            </Row>
         </Container>
    </header>
  )
}

export default Header