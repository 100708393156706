import React from 'react'
import { NavLink } from 'react-router-dom'

const ServicesLayout = (props) => {
  return (
    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 p-2" data-aos="fade-up">
      <NavLink to={props.route} className='text-decoration-none text-dark'>
          <div className="shadow text-center py-4 box draw bg-white">
                <div className="icon py-3"> 
                    {props.logo} 
                </div>
                <h6 className='text-uppercase'><b>{props.caption}</b></h6>
            </div>
      </NavLink>
   </div>
  )
}

export default ServicesLayout